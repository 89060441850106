@import 'fonts/inter/inter.scss';
@import 'fonts/noto-sans/noto.scss';

$landing: #505788;
$landing-dark: #2A326A;
$landing-light: #9DA6E8;
$landing-button-primary: #605FC3;
$landing-button-default: $landing-light;
$landing-light-grey: #f5f5f5;
$landing-grey: #ededed;
$landing-text-button: $landing-light-grey;

$font-inter: 'Inter', 'Open Sans', helvetica, arial, sans-serif;
$font-noto: 'Noto Sans', 'Open Sans', helvetica, arial, sans-serif;

$landing-bg-pattern: url('home/images/landing_bg_pattern.svg');


body.landing-page {
  background-color: $landing-light-grey;
  color: $landing;
  font-family: $font-inter;
  font-size: 18px;
  font-weight: 400;
}

.landing-page {
  img {
    display: block;
    max-width: 80%;
    height: auto;
  }

  @media (min-width:768px) {
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }

  @media (min-width:1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
      max-width:1270px
    }
  }

  /* Typography */
  section p {
    font-size: 18px;
    color: $landing;
    font-weight: 400;
  }

  h1, h2, h3, h3, h4 {
    font-family: $font-noto;
    color: $landing-dark;
  }

  h1 {
    font-size: 55px;
    font-weight: 700;
  }
  h2 {
    font-size: 50px;
    font-weight: 600;
  }


  @media (max-width:768px) {
    h1 {
      font-size:30px;
    }
    h2 {
      font-size:25px;
    }
  }

  .text-light {
    color: $landing-light !important;
  }

  /* Buttons */
  .btn {
    border-radius: 2rem;
    font-size: inherit;
    font-weight: 500;
    padding: .8rem 2rem;
  }

  .btn-info, .btn-info:hover {
    background-color: $landing-button-default;
    border-color: $landing-button-default;
    color: $landing-text-button;
  }
  .btn-info:hover {
    background-color: $landing-dark;
    border-color: $landing-dark;
  }

  .btn-primary {
    background-color: $landing-button-primary;
    border-color: $landing-button-primary;
    color: $landing-text-button;
  }
  .btn-primary:hover {
    background-color: $landing-dark;
    border-color: $landing-dark;
  }
  .btn-default:hover {
    background-color: $landing-light;
    border-color: $landing-light;
  }
  .btn-github {
    position: relative;
  }
  .btn-github:before {
    font-family: "FontAwesome";
    content: "\f09b";
    font-size: 2em;
    display: inline;
    position: absolute;
    left: 30px;
    top: 0;
  }


  /* Mobile navbar toggle button */
  .btn-info.navbar-toggler {
    padding: 10px 20px;
    font-weight: 600;
    border-radius: 3px;
  }

  /* Login and signup buttons */
  .navbar-right .btn-primary.btn-sm,
  .navbar-right .btn-info.btn-sm,
  {
    padding: .25rem 1.4rem;
    font-size: 18px;
  }


  /* Mobile navbar  */
  @media (max-width: 768px) {
    .navbar-right li {
      margin-right: 10px;
    }

    .navbar-right .btn-primary.btn-sm,
    .navbar-right .btn-info.btn-sm,
    {
      padding: 0.8rem 2rem;
      width: 100%;
    }
  }


  /* Desktop navbar - paddings */
  @media (min-width: 768px) {
    .nav > li > a.nav-link {
      padding: 2em 1.2rem;
    }

    .navbar-scroll .navbar-nav > li > a.nav-link {
      padding: 2em 1.2rem;
    }
  }

  /* Navbar links */
  .navbar-default .nav li a.nav-link {
    font-family: $font-noto;
    font-weight: 500;
    text-transform: none;
    font-size: 18px;
    color: $landing-dark;
  }

  .navbar-scroll.navbar-default .nav li a.nav-link,
  .navbar-scroll.navbar-default .nav li a.nav-link:hover {
    color: $landing-dark;
  }

  .navbar-nav > li > a.nav-link {
    border-top: 0;
  }

  .navbar-default .navbar-nav > .active > a.nav-link,
  .navbar-default .navbar-nav > .active > a.nav-link:hover {
    border-top: 0;
    color: $landing-dark;
  }


  /* Background pattern */
  .bg-pattern {
    position:relative;
  }
  .bg-pattern::before {
    content: " ";
    position: absolute;
    height: 1050px;
    left: 0;
    right:0;
    top: -40px;
    z-index: -1;
    background-image: $landing-bg-pattern;
    background-position: 50% -50px;
    background-size: cover;
  }

  /* Jumbotron */
  .jumbotron {
    background-color: transparent;
  }

  /* SDK section */
  .sdk-info {
    z-index: 3;
    position:relative;
    overflow: visible;

    .container {
      overflow: visible;
    }

    .panel {
      border: 0px;
      border-radius: 40px;
      background: rgb(250,178,255);
      background: linear-gradient(160deg, rgba(250,178,255,1) -80%, rgba(90,4,229,1) 100%);
      position: relative;
      overflow: visible;

      .content {
        overflow: hidden;
      }
    }


    @media (min-width:992px) {
      .panel:before {
        content: " ";
        background-image: url('home/images/sdk_background.svg');
        background-repeat: no-repeat;
        background-position: top -210px right -165px;
        position:absolute;
        left:0;
        top:0;
        right:0;
        height:100%;
        border-radius: 40px;
      }

      .panel:after {
        pointer-events: none;
        content: " ";
        background-image: url('home/images/sdk_computer.svg');
        background-repeat: no-repeat;
        background-position: top right;
        position:absolute;
        left:0;
        top:-30px;
        bottom:0;
        right:80px
      }
    }


    .btn.download-sdk {
      width:100%;
    }
  }

  /* About us section */
  .about {
    background-color: $landing-grey;
    border-radius: 50px;
    margin-top: -70px;
    z-index: 2;
    position:relative;



    .panel {
      height: 100%;
      border-radius: 30px;
      background-image: $landing-bg-pattern;
      background-position: top 180px right -1200px;
      background-repeat: no-repeat;

      img {
        width:70px;
        height:70px;
        filter: brightness(0) saturate(100%) invert(75%) sepia(88%) saturate(1623%) hue-rotate(191deg) brightness(94%) contrast(93%);
      }
    }
    .col-md-6:last-child .panel {
      background-position: top -450px right -300px;
    }

    .btn {
      width:100%;
    }
  }

  /* Benefits section */
  .benefits:before {
    content: " ";
    background-image: url('home/images/benefits_bg.svg');
    background-repeat: no-repeat;
    background-position: top 0 left -350px;
    position:absolute;
    border-radius: 0 0 50px 50px;
    left:0;
    top:0;
    bottom:0;
    right:0;
  }

  .benefits {
    border-radius: 0 0 50px 50px;
    background: rgba(50,42,88,1);
    background: linear-gradient(45deg, rgba(50,42,88,1) 70%, rgba(250,178,255,1) 200%);
    position:relative;
    margin-top: -50px;
    padding-top: 50px;
    z-index: 1;

    .artwork-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    ol {
      list-style-type: decimal-leading-zero;
    }
    li {
      font-family: $font-noto;
      font-size: 25px;
      font-weight: 500;
      margin-bottom: 1.8em;
    }
    li::marker {
      color: rgba(100, 100, 100, 45);
    }
    li span {
      position: relative;
      left: 10px;
    }
  }

  /* Allows section */
  .allows.bg-pattern::before {
    background-position: 50% 200px;
  }

  .allows {

    .panel {
      background-color: #605FC3;
      border-radius: 20px;
      font-family: $font-noto;
      font-weight: 500;
      font-size: 25px;
      height: 100%;
      display: flex;
      align-items: center;
    }

  }

  /* Technology section */
  .technology {
    .panel {
      background-color:#EFEFEF;
      font-family: $font-noto;
      font-weight: 500;
      font-size: 25px;
      color: $landing-dark;
      border-radius: 20px;
      height: 100%;
    }

  }

  /* Advantages Section */
  .advantages:before {
    content: " ";
    background-image: url('home/images/advantages_bg.svg');
    background-repeat: no-repeat;
    background-position: top 0 right -200px;
    position:absolute;
    border-radius: 50px;
    left:0;
    top:0;
    bottom:0;
    right:0;
  }

  .advantages {
    border-radius: 50px;
    background: rgb(250,178,255);
    background: linear-gradient(142deg, rgba(250,178,255,1) -150%, rgba(50,42,88,1) 30%);
    position: relative;

    .artwork-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .artwork {
      display: flex;
    }

    ol {
      list-style-type: decimal-leading-zero;
    }
    li {
      font-family: $font-noto;
      font-size: 25px;
      font-weight: 500;
      margin-bottom: 1.8em;
    }
    li::marker {
      color: rgba(100, 100, 100, 45);
    }
    li span {
      position: relative;
      left: 10px;
    }
  }

  /* Pricing Section */
  .pricing {
    div.col-lg-4:first-child .pricing-plan {
      background: rgb(250,178,255);
      background: linear-gradient(140deg, rgba(250,178,255,1) -80%, rgba(90,4,229,1) 100%);

      .pricing-desc {
        color: white;
      }

      p {
        color: white;
      }

      li {
        color:white;
      }

      img {
        position: relative;
        bottom: -40px;
      }
    }

    @media (min-width:768px) {
      div.col-lg-4:first-child .pricing-plan:after {
        content: "  ";
        background-image: url("home/images/rocket.svg");
        background-repeat: no-repeat;
        background-position: bottom center;
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        border-radius: 40px;
      }
    }

    .pricing-plan {
      border-radius: 30px;
      background-color: #E3E6FF;
      margin: auto;
      padding:40px;
      height: 100%;

      ul {
        border-radius: 10px;
        background-color: rgba(96, 95, 195, 0.08);
        list-style: none;
        padding: 5px;
      }
      li {
        border: none;
        color: rgba(80, 87, 136, 1);
        margin: 0 10px;
        text-align: left;

        display: flex;
        align-items: center;
        padding: .4em;
      }
      li::before {
        content: "";
        width: 0.6rem;
        height: 0.6rem;
        border-radius: 50%;
        background: rgba(96, 95, 195, 1);
        margin-right: .6em;
      }
      .pricing-title {
        background:none;
        font-family: $font-noto;
        font-weight: 600;
        font-size: 50px;
        color: $landing-dark;
        text-align: center;
      }
      .pricing-desc {
        margin-top: 20px;
        margin-bottom: 20px;
      }
      .pricing-price {
        text-align: center;
        margin-bottom:20px;

        span {
          color: $landing-dark;
          font-size: 60px;
          font-weight: 700;
          font-family: $font-noto;
        }
      }
      .pricing-cta {
        text-align: center;

        .btn {
          width: 100%;
        }
      }
    }

  }

  /* FAQ */
  .faq {

    background-color: $landing-grey;
    border-radius: 50px;


    .accordion {
      .card {
        border: 0;
        border-radius: 30px;
        margin-bottom:20px;
        position: relative;

        .card-header {
          background-color: transparent;
          border: 0;
          padding:0;

          button {
            padding: 10px 60px 10px 30px;
            width: 100%;
            text-align: left;
          }
        }

        .card-body {
          padding: 10px 30px 30px;
        }

      }

      .card::after {
        content: url("home/images/icons/plus_sign.svg");
        font-size:40px;
        display:block;
        position:absolute;
        top:0px;
        right: 20px;
        pointer-events: none;
      }

      .card:has(>div.collapsing)::after,
      .card:has(>div.show)::after{
        transform: rotate(45deg);
      }

      h5 {
        font-family: $font-noto;
        font-size: 25px;
        font-weight: 500;
        color: $landing-dark;
      }

    }

  }

  /* Footer */
  .footer:before {
    background-position: 50% 0px;
    top:0;
    bottom:0;
    height: auto;
  }

  .footer {
    border: none;
    position: relative;
    background-color:  transparent;
    font-family: $font-noto;
    font-size: 18px;
    font-weight: 500;
    color: $landing-dark;

    a.nav-link {
      color: $landing-dark;
      padding: 2em 1.2rem;
    }

    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;

      .footer-navbar {
        display:flex;
        flex-basis: auto;
        flex-grow: 1;
        align-items: center;

        ul {
          display:flex;
          list-style: none;
          flex-direction: row;
          flex-wrap: wrap;
          margin-bottom:0px;
        }

        ul.footer-navbar-right {
          .btn {
            padding: 0.25rem 1.4rem;
          }
        }
      }

    }

    .footer-privacy {
      font-family: $font-inter;
      font-weight: 400;
      color: rgba(80, 87, 136, 1);

    }
  }

  .contact {
    background: transparent none;

    .content {
      background-color: #E3E6FF;
      border-radius: 30px;
    }

    .form-control {
      border: 0;
      border-radius: 2rem;
      min-height: 54px;
      color: $landing;
      font-family: $font-noto;
      font-size: 18px;
      font-weight: 500;
      padding: 0.8rem 2rem;
    }

    .form-control::placeholder {
      color: rgba(0, 0, 0, 0.3);
    }

    .btn {
      width: 100%;
    }

    textarea {

      resize: none;
    }

  }

  @media (max-width:768px) {
    .footer {
      display:block;
      text-align:center;

      a.logo {
        display: block;
        text-align:center;
        margin: 0;

        img {
          margin-right: auto;
          margin-left: auto;
        }
      }

      .container {
        display:block;

        .footer-navbar {
          display:block;

          ul {
            padding: 0;
            display:block;

            li {
              padding: 0;
              margin: 0 0 10px;

              a.nav-link {
                padding:1rem;
              }

              .btn {
                padding: 0.8rem 2rem !important;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }


}
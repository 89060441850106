// Basic Colors
$navy: #aa9de8;       // Primary color
$dark-gray: #c2c2c2;  // Default color
$blue: #1c84c6;       // Success color
$lazur: #00b3ff;      // Info color
$yellow: #f8ac59;     // Warning color
$red: #ED5565;        // Danger color

// Various colors
$text-color: #676a6c;   // Body text
$gray: #F8F9FD;         // Background wrapper color
$light-gray: #D1DADE;   // Default label, badge
$label-badge-color: #5E5E5E;
$light-blue:#f3f6fb;

// Spinner color and margin
$spin-color: $navy;
$spin-margin: 0 auto;

//Basics
$basic-link-color: #aa9de8;

// IBOX colors ( default panel colors)
$border-color: #efeffc;     // IBox border
$ibox-title-bg:#ffffff;     // IBox Background header
$ibox-content-bg:#ffffff;   // IBox Background content

//Sidebar width
$sidebar-width: 220px;

// Boxed layout width
$boxed-width: 1200px;
$boxed-background: url('patterns/shattered.png');

//Border radius for buttons
$btn-border-radius: 3px;

//Navigation
$nav-bg: #F8F9FD;
$nav-profile-pattern: url("patterns/header-profile.png");
$nav-text-color: #6a748a;
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "variables";
@import "mixins";

/**
Custom styles for Fenrir Cloud dashboard that override
bootstrap and/or Inspinia theme styles
 */

/* Logo */
.logo-header {
  text-align:left;
  margin-bottom:30px;
}
body.body-small .logo-header {
  text-align:center;
}
.text-logo {
  color:$navy;
}


/* Nav */
.nav > li > a {
  font-size:14px;
  font-weight: 400;
}

/* iBox  */
.ibox {
  box-shadow: 0 0 25px 0 rgba(99,91,135,0.07);
}

.ibox-title {
  color: lighten($text-color, 10);
}

/* Tables */
.table-striped tbody tr:nth-of-type(2n+1) {
  background-color: $border-color; // Override bootstrap stripped table background
}

/* Semi-Transparent Alerts */
$alert-bg-transparent-alpha: .5;
@each $color, $value in $theme-colors {
  .alert-#{$color}.alert-transparent {
    @include alert-variant(rgba(theme-color-level($color, $alert-bg-level), $alert-bg-transparent-alpha), theme-color-level($color, $alert-border-level), theme-color-level($color, $alert-color-level));
  }
}


/* Vertical alignment */
.v-al-bl {
  vertical-align: baseline;
}

.v-al-t {
  vertical-align: top;
}

.v-al-m {
  vertical-align: middle;
}

.v-al-b {
  vertical-align: bottom;
}

.v-al-s {
  vertical-align: sub;
}

.v-al-tt {
  vertical-align: text-top;
}


/* Table vertical alignment */
.table > thead > tr > th.v-al-bl, .table > tbody > tr > th.v-al-bl, .table > tfoot > tr > th.v-al-bl, .table > thead > tr > td.v-al-bl, .table > tbody > tr > td.v-al-bl, .table > tfoot > tr > td.v-al-bl {
  vertical-align: baseline;
}

.table > thead > tr > th.v-al-t, .table > tbody > tr > th.v-al-t, .table > tfoot > tr > th.v-al-t, .table > thead > tr > td.v-al-t, .table > tbody > tr > td.v-al-t, .table > tfoot > tr > td.v-al-t  {
  vertical-align: top;
}

.table > thead > tr > th.v-al-m, .table > tbody > tr > th.v-al-m, .table > tfoot > tr > th.v-al-m, .table > thead > tr > td.v-al-m, .table > tbody > tr > td.v-al-m, .table > tfoot > tr > td.v-al-m {
  vertical-align: middle;
}

.table > thead > tr > th.v-al-b, .table > tbody > tr > th.v-al-b, .table > tfoot > tr > th.v-al-b, .table > thead > tr > td.v-al-b, .table > tbody > tr > td.v-al-b, .table > tfoot > tr > td.v-al-b {
  vertical-align: bottom;
}

.table > thead > tr > th.v-al-s, .table > tbody > tr > th.v-al-s, .table > tfoot > tr > th.v-al-s, .table > thead > tr > td.v-al-s, .table > tbody > tr > td.v-al-s, .table > tfoot > tr > td.v-al-s {
  vertical-align: sub;
}

.table > thead > tr > th.v-al-tt, .table > tbody > tr > th.v-al-tt, .table > tfoot > tr > th.v-al-tt, .table > thead > tr > td.v-al-tt, .table > tbody > tr > td.v-al-tt, .table > tfoot > tr > td.v-al-tt {
  vertical-align: text-top;
}

/* Fixing bootstrap override bug for list items */
.list-group-item {
  & + & {
    border-top-width: 1px;
  }
}

/* Side menu navigation, with NavLink */
#side-menu.nav > li > a.active {
  color: #ffffff;
  border-left: 4px solid darken($navy, 2%);
  background: darken($nav-bg, 3%);
}

/* Account pages */
.loginColumns {
  padding-top: 100px;
}

body.body-small .loginColumns {
  padding-top: 30px;
}

/* Buttons */
.btn-primary  {
  background-color: darken($navy, 7);
  border-color: darken($navy, 7);
}

.btn:disabled {
  cursor: default;
}

.btn.active:disabled, .btn:active:disabled {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: $navy;
  border-color: $navy;
}

/* Alerts */
.alert {
  border-radius: 0px;
}

